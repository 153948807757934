import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';

const UserDashboard = () => {

  const random = () => Math.round(Math.random() * 100)

  const [loadingStats, setLoadingStats] = useState(false)

  const [dashboardData, setDashboardData] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/users/getDashboardData`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setDashboardData(response.data.data)

    setOnLoading(false);
  };

  console.log('dashboardData', dashboardData)

  const uniqueFindingDistributioChartData = {
    series: [100, 200, 300, 400, 150],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Critical', 'High', 'Medium', 'Low', 'Info'],
      colors: ['#FF0000', '#A6001B', '#FFC300', '#B3FFB3', '#555'],
      legend: {
        position: 'bottom',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  };

  const cweLabels = ['CWE-787', 'CWE-79', 'CWE-89', 'CWE-416', 'CWE-78', 'CWE-20', 'CWE-125', 'CWE-22', 'CWE-352', 'CWE-434', 'CWE-862', 'CWE-476', 'CWE-287', 'CWE-190', 'CWE-502', 'CWE-77', 'CWE-119', 'CWE-798', 'CWE-918', 'CWE-306', 'CWE-362', 'CWE-269', 'CWE-94', 'CWE-863', 'CWE-276'];
    const cweValues = [30, 20, 15, 25, 10, 28, 18, 12, 22, 35, 17, 27, 14, 23, 31, 29, 24, 21, 19, 26, 16, 33, 32, 36, 34];

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 400,
            toolbar: {
                show: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '50%' // Adjust column width as needed
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: cweLabels,
            labels: {
                show: true
            }
        },
        yaxis: {
            labels: {
                show: true
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8']
    };

    const series = [{
        data: cweValues
    }];

    
  const owaspLabels = ['A01:2021 - Broken Access Control', 
  'A02:2021 - Cryptographic Failures',
   'A03:2021 - Injection', 
   'A04:2021 - Insecure Design', 
   'A05:2021 - Security Misconfiguration',
    'A06:2021 - Vulnerable and Outdated Components', 
    'A07:2021 - Identification and Authentication Failures',
     'A08:2021 - Software and Data Integrity Failures', 
     'A09:2021 - Security Logging and Monitoring Failures', 
     'A10:2021 - Server-Side Request Forgery (SSRF)' ];
  const owaspValues = [30, 20, 15, 25, 10, 28, 18, 12, 22, 35, ];

  const owaspChartOptions = {
      chart: {
          type: 'donut'
      },
      labels: owaspLabels,
      legend: {
        position: 'bottom'
      }
  };

  const owaspChartSeries = owaspValues;


  return (
    <>

      <CContainer>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', backgroundColor: '#fff',
            borderRadius: 10, padding: 20,
          }}>

            <Link to="/users"
              style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <FaUsers size={40} style={{ color: '#ffaa05' }} />

            </Link>

            <Link to="/users" style={{ textDecoration: 'none' }}>

              <div className="dashboardLinkDiv">

                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.usersCount}</h3>
                }

                <span style={{ fontSize: 16, color: '#2f2f3b', }}>Users</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/asset-groups" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaObjectGroup size={40} style={{ color: '#7366ff' }} />
            </Link>

            <Link to="/asset-groups" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.assetGroupsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#7366ff', }}>Asset Groups</span>
              </div>
            </Link>

          </div>

          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/business-units" style={{
              textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center',
              justifyContent: 'center'
            }}>
              <IoIosBusiness size={40} style={{ color: '#1ecbe1' }} />
            </Link>

            <Link to="/asset-groups" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.businessUnitsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#7366ff', }}>Business Units</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20,
          }}>

            <Link to="/applications" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdApps size={40} style={{ color: '#f73164', }} />
            </Link>

            <Link to="/applications" style={{ textDecoration: 'none', width: '50%', }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.applicationsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Applications</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/scans" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AiOutlineSecurityScan size={40} style={{ color: '#ffaa05' }} />
            </Link>

            <Link to="/scans" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.scansCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#ffaa05', }}>Scans</span>
              </div>
            </Link>
          </div>

          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdErrorOutline size={40} style={{ color: '#f73164' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.issuesCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Issues</span>
              </div>
            </Link>
          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SiHelpdesk size={40} style={{ color: '#a12dd2' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.openTicketsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Open Tickets</span>
              </div>
            </Link>
          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SiHelpdesk size={40} style={{ color: 'green' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#2f2f3b', fontWeight: 600, }}>{dashboardData.closedTicketsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Resolved Tickets</span>
              </div>
            </Link>
          </div>




        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>APPLICATIONS</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <MdOutlineApps size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>78</div>
                  <span style={{ fontSize: 13 }}>Total Applications Scanned</span>
                </div>


              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <AiFillFolderOpen size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>5902</div>
                  <span style={{ fontSize: 13 }}>Total Application Findings</span>
                </div>


              </div>

            </div>



          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>TOP OPEN APPLICATION FINDINGS</span>
            <hr style={{ borderColor: '#fff' }} />



            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiBoxList size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>78</div>
                  <span style={{ fontSize: 13 }}>Top 25 CWE</span>
                </div>


              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiViewList size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>760</div>
                  <span style={{ fontSize: 13 }}>OWASP Top 10</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaListUl size={50} style={{ color: 'rgb(247, 49, 100)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(247, 49, 100)', fontSize: 25 }}>400</div>
                  <span style={{ fontSize: 13 }}>Top 25 PCI DSS</span>
                </div>


              </div>
            </div>
          </div>




          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '100%'
          }}>





            <span style={{ fontSize: 15 }}>APPLICATION FINDINGS BY TYPE</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CgWebsite size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>714</div>
                  <span style={{ fontSize: 13 }}>Websites</span>
                </div>


              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaMobileAlt size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>405</div>
                  <span style={{ fontSize: 13 }}>Mobile Apps</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiDesktop size={50} style={{ color: 'rgb(247, 49, 100)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(247, 49, 100)', fontSize: 25 }}>2677</div>
                  <span style={{ fontSize: 13 }}>Desktop Apps</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <MdMiscellaneousServices size={50} style={{ color: 'rgb(161, 45, 210)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(161, 45, 210)', fontSize: 25 }}>2106</div>
                  <span style={{ fontSize: 13 }}>Others</span>
                </div>


              </div >


            </div>



          </div>





          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '40%'
          }}>


            <span style={{ fontSize: 15 }}>TOP 10 VULNERABLE APPLICATIONS</span>
            <hr style={{ borderColor: '#fff' }} />


            <table style={{ width: '100%' }}>

              <thead>

                <th style={{padding:15}}></th>
                <th style={{padding:15}}>
                  <div style={{backgroundColor:'#FF0000', padding:5, fontSize:13, borderRadius:5, textAlign:'center'}}>CRITICAL</div>
                </th>
                <th style={{padding:15}}>
                  <div style={{backgroundColor:'#A6001B', padding:5, fontSize:13, borderRadius:5, textAlign:'center'}}>HIGH</div>
                </th>
                <th style={{padding:15}}>
                  <div style={{backgroundColor:'#FFC300', padding:5, fontSize:13, borderRadius:5, textAlign:'center',color:'#000',}}>MEDIUM</div>
                </th>
                <th style={{padding:15}}>
                  <div style={{backgroundColor:'#B3FFB3', padding:5, fontSize:13, borderRadius:5, color:'#000', textAlign:'center'}}>LOW</div>
                </th>
                <th style={{padding:15}}>
                  <div style={{backgroundColor:'#555', padding:5, fontSize:13, borderRadius:5, color:'#fff', textAlign:'center'}}>INFO</div>
                </th>


              </thead>

              <tbody>

                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 1
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       

                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 2
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       


                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 3
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       


                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 4
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       

                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 5
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       


                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 6
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       

                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 7
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       

                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 8
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       


                <tr>
                  <td style={{textAlign:'left', fontSize:13, fontWeight:'bold'}}>
                    Application 9
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>       

                <tr>
                  <td style={{textAlign:'left', fontSize:13,fontWeight:'bold'}}>
                    Application 10
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    100
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    200
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    3300
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    334
                  </td>
                  <td style={{textAlign:'center', fontSize:13}}>
                    123
                  </td>
                </tr>           



              </tbody>


            </table>



          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '55%'
          }}>


            <span style={{ fontSize: 15 }}>UNIQUE FINDING DISTRIBUTION BY SEVERITY</span>
            <hr style={{ borderColor: '#fff' }} />

<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>

<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'45%', height:'500px'}}>
  <div style={{display:'flex', alignItems: 'center', marginTop:50}}>
    <Chart 
      options={uniqueFindingDistributioChartData.options} 
      series={uniqueFindingDistributioChartData.series} 
      type="donut" 
      height="300px"
      style={{margin: '0 auto'}}  // Center the chart horizontally
    />
  </div>
</div>






<div style={{width:'55%'}}>

  <table>

      <thead>
          <th style={{padding:10}}></th>
          <th style={{padding:10, textAlign:'center'}}>Application Assets</th>
          <th style={{padding:10, textAlign:'center'}}>Application Locations</th>
      </thead>

      <tbody>

          <tr>

            <td style={{textAlign:'left'}}>
                <div style={{backgroundColor:'#FF0000', padding:5, fontSize:13, borderRadius:5, textAlign:'center'}}>CRITICAL</div>
            </td>
            <td style={{textAlign:'center'}}>100</td>
            <td style={{textAlign:'center'}}>1008</td>

          </tr>

          <tr>

            <td style={{textAlign:'left'}}>
                <div style={{backgroundColor:'#A6001B', padding:5, fontSize:13, borderRadius:5, textAlign:'center'}}>HIGH</div>
            </td>
            <td style={{textAlign:'center'}}>100</td>
            <td style={{textAlign:'center'}}>1008</td>

          </tr>


          <tr>

            <td style={{textAlign:'left'}}>
                <div style={{backgroundColor:'#FFC300', padding:5, fontSize:13, borderRadius:5, textAlign:'center', color:'#000'}}>MEDIUM</div>
            </td>
            <td style={{textAlign:'center'}}>100</td>
            <td style={{textAlign:'center'}}>1008</td>

          </tr>


          <tr>

            <td style={{textAlign:'left'}}>
                <div style={{backgroundColor:'#B3FFB3', padding:5, fontSize:13, borderRadius:5, textAlign:'center',color:'#000'}}>LOW</div>
            </td>
            <td style={{textAlign:'center'}}>100</td>
            <td style={{textAlign:'center'}}>1008</td>

          </tr>


          <tr>

            <td style={{textAlign:'left'}}>
                <div style={{backgroundColor:'#555', padding:5, fontSize:13, borderRadius:5, textAlign:'center'}}>CRITICAL</div>
            </td>
            <td style={{textAlign:'center'}}>100</td>
            <td style={{textAlign:'center'}}>1008</td>

          </tr>
      </tbody>
  </table>


</div>
</div>



          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>CWE TOP 25 MOST DANGEROUS SOFTWARE ERRORS</span>
            <hr style={{ borderColor: '#fff' }} />              



                                <Chart options={chartOptions} series={series} type="bar" height="90%"/>




          </div>



          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>OWASP DISTRIBUTION</span>
            <hr style={{ borderColor: '#fff' }} />

          <table>

              <thead>
                 <th style={{padding:5}}>OWASP Top 10 Category</th>
                 <th style={{padding:5}}>Affected Applications</th>
                 <th style={{padding:5}}>Application Locations</th>
                 <th style={{padding:5}}>Related Open Findings</th>
              </thead>

              <tbody>

                <tr>
                   <td >
                     <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>A01:2021 - Broken Access Control
                     </div>
                     </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A02:2021 - Cryptographic Failures
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A03:2021 - Injection
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A04:2021 - Insecure Design
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A05:2021 - Security Misconfiguration
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A06:2021 - Vulnerable and Outdated Components
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A07:2021 - Identification and Authentication Failures
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A08:2021 - Software and Data Integrity Failures
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A09:2021 - Security Logging and Monitoring Failures
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>

                 <tr>
                   <td>
                   <div style={{backgroundColor:'#fff', color:'#000', padding:5, }}>
                    A10:2021 - Server-Side Request Forgery (SSRF)
                    </div>
                    </td>
                   <td>100</td>
                   <td>200</td>
                   <td>300</td>
                 </tr>




              </tbody>
          </table>




          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>APPLICATION FINDINGS (DISCOVERED VS RESOLVED)</span>
            <hr style={{ borderColor: '#fff' }} />






          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '48%'
          }}>


            <span style={{ fontSize: 15 }}>TOP 10 UNIQUE APPLICATION FINDINGS</span>
            <hr style={{ borderColor: '#fff' }} />






          </div>




        </div>


      </CContainer>
    </>
  )
}

export default UserDashboard