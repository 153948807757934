import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

const Applications = () => {

  //const [toast, addToast] = useState(0)
  const navigate = useNavigate()

  const [applications, setApplications] = useState([])
  const [onLoading, setOnLoading] = useState(false);

  const [pageCount, setPageCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(100);
  const [onDeleting, setOnDeleting] = useState(false);

  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const customStyles = {
    content: {
      top: '30%',
      left: '25%',
      width: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '15%',
      backgroundColor: '#ffffff',
      borderRadius: 15,
      borderColor: 'ffffff'
    },
  };

  const itemsPerPage = 10;

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {

    var requestedPage = event.selected + 1;

    fetchApplications(true, requestedPage);

    const newOffset = (event.selected * itemsPerPage) % totalRecords;
    
    setItemOffset(newOffset);

  };


  // Function to handle the button click and show the confirm dialog
  const handleClick = (user) => {
    setApplicationToDelete(user);
    setModalIsOpen(true);
  };

  const goToApplication = (id) => {
    navigate('/application?applicationId=' + id);
  };

  const goToEditApplication = (id) => {
    navigate('/edit-application?applicationId=' + id);
  };

  // Function to handle the confirmation action
  const handleConfirmation = (confirmed) => {

    if (confirmed) {
      // Call the function with the value passed to this component
      deleteFunction(applicationToDelete);
    }
    setModalIsOpen(false);
  };

  // Function to delete the scan
  const deleteFunction = (app) => {
    deleteApplication(app)
  };

  const closeModal = async () => {

    setModalIsOpen(false);
  };


  const deleteApplication = async (id) => {

    // Construct the request body
    const requestBody = {
      id: id
    };

    // Retrieve the bearer token from localStorage
    const bearerToken = localStorage.getItem('ASIToken');

    try {

      // Make the API request
      const response = await axios.post('api/v1/applications/deleteApplication', requestBody, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      // Handle the API response
      setOnDeleting(false);


      if (response.data.hasOwnProperty('error')) {

        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setOnDeleting(false);

      } else {

        toast('Application deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        //window.location.reload();
        setOnDeleting(false);

        // Update the applications array by removing the deleted application
        setApplications((prevApplications) => {
          return prevApplications.filter((app) => app._id !== id);
        });

        fetchApplications();
      }


    } catch (error) {
      // Handle API error
      console.error('Error:', error);
      setOnDeleting(false)
    }

  }


  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )


  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const isFirstTime = useRef(true);


  const fetchApplications = async (isFirstTime, pageNumber) => {

    if (isFirstTime) {
      setOnLoading(true);
    }
  
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/applications/getAllApplications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setApplications(response.data.applications);    
  
    setOnLoading(false);
  };


  

  const goToAddApplication = async () => {

    navigate('/add-application');        

  }
  useEffect(() => {      

    // Make the initial call immediately
    fetchApplications(true, currentPage);

  }, []);

  

  const columns = [
    '',
    "Application Name",
    "Type",
    {
      label: "Risk Rating",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {

          let bgColor;
          let theColor;

          if (value == 'CRITICAL') {

            bgColor = '#FF0000';
            theColor = '#FF0000';

          } else if (value == 'HIGH') {

            bgColor = '#A6001B';
            theColor = '#fff';


          } else if (value == 'MEDIUM') {

            bgColor = '#FFC300';
            theColor = 'black';

          } else if (value == 'LOW') {

            bgColor = '#B3FFB3';
            theColor = '#000';

          } else if (value == 'Best Practice') {

            bgColor = '#fdfe25';
            theColor = '#000';
          }

          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <text style={{
                padding: 5, backgroundColor: bgColor, color: theColor, width: 120,
                textAlign: 'center', borderRadius: 10, fontSize: 13
              }}>{value}</text>

            </div>
          )
        }
      }
    },
    "Business Impact",
    "Business Unit",
    "Asset Group",
    "Business Owner",
    "Active Issues",
    "Testing Status",    
    {
      label: "VIEW",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

                <CButton color="primary" variant="outline"
                  onClick={() => goToApplication(value._id)}
                  className="m-2" style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color: '#fff', borderColor:'#fff' }}>
<FaEye size={15} style={{ color: 'white' }} />
                   <span style={{marginLeft:5}}> View</span>
                    </CButton>                

            </div>
          )
        }
      }
    },
    {
      label: "ACTIONS",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<
            div style={{
              display: "flex",
              alignItems: "center"
            }
            } >
            <CButton color="info"
            onClick={() => goToEditApplication(value)}
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'blue', borderColor:'blue' }}>
                  <CiEdit size={15} style={{ color: 'white' }} />
                   <span style={{marginLeft:5}}></span>
              </CButton>

            <CButton color="danger"
              onClick={() => handleClick(value)}
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red' }}>
                  <MdDeleteOutline size={15} style={{ color: 'white' }} />
                   <span style={{marginLeft:5}}></span>
              </CButton>
          </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })


  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'No applications created yet',
      }
    }
  };


  var tableData = [];

  console.log('applications:',applications)


  for (var i = 0; i < applications.length; i++) {

    var dataItem = [];

    dataItem.push(i+1);
    dataItem.push(applications[i].name);
    dataItem.push(applications[i].type);
    dataItem.push(applications[i].riskRating?applications[i].riskRating:'---');
    dataItem.push(applications[i].businessImpact);

    if(applications[i].businessUnit){
      dataItem.push(applications[i].businessUnit.name);
    }else{
      dataItem.push('');
    }


    if(applications[i].assetGroup){
      dataItem.push(applications[i].assetGroup.name);
    }else{
      dataItem.push('');
    }

    if(applications[i].businessOwner){
      dataItem.push(applications[i].businessOwner.firstName + ' '  +  applications[i].businessOwner.lastName);
    }else{
      dataItem.push('');
    }

    dataItem.push(applications[i].issues);
    dataItem.push(applications[i].testingStatus);
   
    dataItem.push(applications[i]); // for view report link
    dataItem.push(applications[i]._id); // for delete

    tableData.push(dataItem);
  }

 


  return (
    <div className="activeScans">

      {setModalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >
          <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this application? This will also delete all SASt scans under it</text>
          <br/><br/>
          <button onClick={() => handleConfirmation(true)} style={{ width: 100, borderWidth: 0, borderRadius:5, backgroundColor: 'green', color:'white', padding: 10 }}>Yes</button>
          <button onClick={() => handleConfirmation(false)} style={{ marginLeft: 30, borderWidth: 0, borderRadius:5, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>No</button>
        </Modal>
      )}


      <div style={{ width: '100%' }}>
        <div>
          <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Applications</h2>

            <CButton
              style={{
                width: 300,
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              onClick={goToAddApplication}
              color="primary"
              className="px-3"
            >
              <AiOutlineAppstoreAdd size={24} style={{ color: 'white' }} />
              <span style={{marginLeft:15}}>Add an Application</span>
            </CButton>
          </div>


          {onLoading &&
            <ShimmerTable row={8} col={10} />
          }


          {!onLoading &&

            <>

              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  style={{ height: "57vh" }}
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
              
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default Applications



